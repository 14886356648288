'use client';
import React, { FC, forwardRef, memo } from 'react';

import { IInput, ITYPE } from './interface';

export const INPUT_TYPES: ITYPE = {
    TEXT     : "text",
    PASSWORD : "password",
    CHECKBOX : "checkbox",
    RADIO    : "radio",
    EMAIL    : "email",
    PHONE    : "phone",
    DATE     : "date",
    NUMBER   : "number"
};

const Input: FC<IInput> = forwardRef<HTMLInputElement, IInput>(function Input({ classes, type = INPUT_TYPES.TEXT, radius, onChange, error, placeholder, name, value, disabled, ...args }, ref) {

    const width = (type === 'text' || type === 'password' || type === 'email' || type === 'phone' || type === 'date') && 'w-full';
    const inpConfig = (type === 'checkbox' || type === 'radio') && "h-5 w-5 cursor-pointer";

    const className = `placeholder:text-grey-800 placeholder:text-sm shadow-xl ${radius ? radius : 'rounded-full'} ${disabled && 'bg-gray-200'} border border-gray-500 tracking-wide py-2 px-6 text-md focus:outline-none ${width} ${inpConfig}`;
    return (
        <div className={width || ''}>
            <input
                ref={ref}
                className={`${className} ${classes}`}
                type={type}
                onChange={onChange}
                placeholder={placeholder}
                name={name}
                value={value}
                disabled={disabled}
                {...args}
            />
            {error && <p className="text-red-500 text-xs mt-1 ml-4">{error}</p>}
        </div>
    );
    });

export default Input;