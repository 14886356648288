"use client";
import moment from "moment";

const EnquiryDetail = ({ homestayEnquiryData, statusType }) => {
  const rooms = Object.entries(homestayEnquiryData.rooms || {}).reduce((acc, [key, value]) => {
    if (value) {
      acc.push([`${key.split('_').join(' ')} - ${value}`]);
    }
    return acc;
  }, []);

  return (
    <div className="flex">
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-2 ">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Homestay Name :</p>
          <p className="capitalize font-['Montserrat'] font-medium">{homestayEnquiryData.homeStayId?.nameToBeDisplayed}</p>
        </div>

        <div className="flex gap-x-2 ">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Adult :</p>
          <p className="font-['Montserrat'] font-medium">{homestayEnquiryData.adults}</p>
        </div>

        <div className="flex gap-x-2">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Rooms:</p>
          {
            rooms.map((item, index) => <div key={index} className="font-['Montserrat'] font-medium capitalize">{item}</div>)
          }     
        </div>      

        <div className="flex gap-x-2">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Check In :</p>
          <p className="font-['Montserrat'] font-medium">{homestayEnquiryData.checkIn ? moment(homestayEnquiryData.checkIn).format("DD-MMM-yy") : "Not Available"}</p>
        </div>

        <div className="flex gap-x-2">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Check Out :</p>
          <p className="font-['Montserrat'] font-medium">{homestayEnquiryData.checkIn ? moment(homestayEnquiryData.checkOut).format("DD-MMM-yy") : "Not Available"}</p>
        </div>

        <div className="flex gap-x-2">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Booking Amount :</p>
          <p className="font-['Montserrat'] font-medium">Rs. {homestayEnquiryData?.priceBreakdown?.totalPrice}</p>
        </div>

       {homestayEnquiryData?.specialRequest && <div className="flex gap-x-2">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Special Request :</p>
          <p className="font-['Montserrat'] font-medium">{homestayEnquiryData?.specialRequest}</p>
        </div>}

        <div className="flex gap-x-2">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Type of Guest :</p>
          <p className="font-['Montserrat'] font-medium">{homestayEnquiryData?.typeOfGuests}</p>
        </div>

        {statusType === 'rejected' && <div className="flex gap-x-2">
          <p className="text-[#3B3B3D] text-base font-['Montserrat'] font-normal">Action :</p>
          <p className="capitalize font-['Montserrat'] font-medium text-red-700">{statusType}</p>
        </div>}
      </div>
    </div >

  );
};

export default EnquiryDetail;