import Button from "../Button";
import { EVARIANTS } from "../Button/interface";
import AdjustRate from "./AdjustRate";
import AdjustRoom from "./AdjustRoom";
import { useState,useEffect } from "react";
import useApi from "../../hooks/useApi";
import { API_ROUTES } from "../../api";
import moment from "moment";
import DatePicker from "../DatePicker/v2";
import useCommonStore from "../../store/store";
import CustomMultiSelect from "../CustomMultiSelect";
import { getUTCDate } from "../../utils/dateFormatter";
import DaysCheckBox from "../DaysCheckBox";
import Card from "./Card/v2";
import Modal from "../Modal/v2";

const BulkUpdate = () => {
  const [showTab, toggleTab] = useState("adjustRate");
  const { data: roomResponse, error:roomError, loading:roomLoading, apiClient: roomClient } = useApi();

  const [roomData, setRoomData] = useState(null);
  const [selectedDate, setToAndFromDate] = useState(null);
  const [selectedRoomData, setSelectedRoomData] = useState(null);
  const [selectedDays,setDays] =  useState([]);
  const {homestayData} = useCommonStore();
  
  const getRoomInRange = async () => {
    const url = API_ROUTES.ROOM_WITHIN_RANGE;
    const options = {
      params: {
        from: getUTCDate(selectedDate?.from),
        to: getUTCDate(selectedDate?.to),
        homeStayId : homestayData?._id
      },
    };

    await roomClient(url, options);
  };


  useEffect(() => {
    if (selectedDate?.to )
    {
      setDays([]);
      getRoomInRange();
    } 
    if(!selectedDate || homestayData)
    {
      setSelectedRoomData(null);
      // setRoomData(null)
    }

   }, [selectedDate?.to, homestayData]);


   useEffect(()=>{
    if(selectedDays?.length>0)
    {  
      // setToAndFromDate(null);
      getRoomInRange();
     }
   },[selectedDays])


  useEffect(() => {
    if (roomResponse?.status) {
      // setSelectedRoomData({  });
      setRoomData(roomResponse?.data);
    }
  }, [roomResponse]);

  const handleRoomCategoryChange = (selectedRoom: any) => {
    const filterRoomData = roomData?.categories.find(
      (room) => room?.roomType === selectedRoom?.roomType
    );
    setSelectedRoomData({
      roomType: filterRoomData?.roomType,
      adults: filterRoomData?.adults || 0,
      price: filterRoomData?.roomPrice || 0 ,
      breakfast: filterRoomData?.meals?.breakfast || 0,
      lunch: filterRoomData?.meals?.lunch || 0,
      dinner: filterRoomData?.meals?.dinner || 0,
      allMeals: filterRoomData?.meals?.allMeals || 0,
      extraBedCharge : filterRoomData?.extraBedCharge || 0,
      maxAdults : filterRoomData?.maxAdults || 0,
      label : filterRoomData?.roomTypeLabel
    });
  };


  const handleTabSwitch = (value: string) => {
    toggleTab(value);
  };


  return (
    <div className=" h-full mb-[100px] md:mt-[80px] px-3  w-11/12 md:max-w-[1440px] px-0 lg:px-24 mx-auto sm:my-24">
      <div className="flex items-center justify-between">
        <Button
          variant={EVARIANTS.LINK}
          className="!px-0"
          onClick={() => handleTabSwitch("adjustRate")}
        >
          <p className="font-['Montserrat'] font-bold text-[18px] leading-[25px] tracking-[0.2px] text-[#000000]">
            ADJUST RATES
          </p>
        </Button>

        <Button
          variant={EVARIANTS.LINK}
          className="!px-0"
          onClick={() => handleTabSwitch("adjustRooms")}
        >
          <p className="font-['Montserrat'] font-bold text-[18px] leading-[25px] tracking-[0.2px] text-[#000000]">
            ADJUST ROOMS
          </p>
        </Button>
      </div>

      <div className="flex">
        <div
          className={`flex-1 h-[4px] ${
            showTab === "adjustRate" ? "bg-[#F75D37]" : "bg-[#D9D9D9]"
          }`}
        ></div>
        <div
          className={`flex-1 h-[4px] ${
            showTab === "adjustRooms" ? "bg-[#F75D37]" : "bg-[#D9D9D9]"
          }`}
        ></div>
      </div>

      <div className="flex md:flex-col  flex-col justify-between my-5  gap-x-5 items-center md:items-start">
        <div className="flex flex-col items-center md:items-start">
          <div className="flex flex-col md:flex-row  md:gap-x-2 gap-y-2">
            <DatePicker
              startDate={selectedDate?.from}
              endDate={selectedDate?.to}
              setToAndFromDate={setToAndFromDate}
            />
          </div>
          {(!selectedDate?.to || !selectedDate?.from) && (
            <p className="text-red-500 text-sm font-medium font-['Montserrat']">
              Select date range to perform bulk operation
            </p>
          )}

          {showTab === "adjustRate" && (
            <p className="text-[18px] font-bold font-['Montserrat'] leading-[14px] mt-3">
              OR
            </p>
          )}
          {showTab === "adjustRate" && (
            <div className="mt-3">
              {/* <CustomMultiSelect setDays={setDays} selectedDays={selectedDays}/> */}
              <DaysCheckBox selectedDays={selectedDays} setDays={setDays} />
              {selectedDays?.length === 0 && (
                <p className="text-red-500 text-sm font-medium font-['Montserrat']">
                  Select day to change rate for days
                </p>
              )}
            </div>
          )}
        </div>


       {/* <p className="font-bold text-lg font-['Montserrat'] mt-4">Room List</p> */}

        {roomData?.categories?.length>0 &&          
          showTab === "adjustRate" && (
            <p className="font-bold text-lg font-['Montserrat'] mt-4">Room List</p>
          )}

        <div className="flex flex-row gap-y-3  w-full">
         { showTab === "adjustRate" && <div className="flex gap-x-2 gap-y-4 w-full flex-col">
            {roomData?.categories?.map((room, index) => (
              <Card
                key={index}
                handleRoomCategoryChange={handleRoomCategoryChange}              
                selectedRoomData={selectedRoomData}
                room={room}               
              />
            ))}
          </div>}
       
          {showTab === "adjustRooms" && 
          <div className="mt-3 w-full">
            {/* {showTab === "adjustRate" && selectedRoomData?.roomType && <AdjustRate selectedDays={selectedDays} setRoomData={setRoomData} getRoomInRange={getRoomInRange} setSelectedRoomData={setSelectedRoomData} rooms={selectedRoomData} loading={roomLoading} selectedDate={selectedDate} />} */}
              <AdjustRoom
                getRoomInRange={getRoomInRange}
                selectedDate={selectedDate}
                loading={roomLoading}
                rooms={roomData}
              />
            
          </div>}
        </div>
        
        <Modal show={selectedRoomData?.roomType}  title={selectedRoomData?.label} preventClose={true} className="md:px-4 md:pb-5 md:mt-2 px-2  pb-5" containerClass="mx-auto"   >
             <AdjustRate selectedDays={selectedDays} setRoomData={setRoomData} getRoomInRange={getRoomInRange} setSelectedRoomData={setSelectedRoomData} rooms={selectedRoomData} loading={roomLoading} selectedDate={selectedDate} />
        </Modal>
       

       
        {/* <div className="flex flex-col md:items-end md:justify-end justify-center gap-y-2">
        <div className="mt-3 md:mt-0">
          {roomData?.categories?.length > 0 && (selectedDate?.to || selectedDays?.length>0) && showTab === 'adjustRate' && (
            <select
              onChange={handleRoomCategoryChange}
              id="roomCategory"
              name="roomCategory"
              className="h-[53px] px-3 w-full md:border-none border-t-[1px] border-b-[1px] border-[#D9D9D9] md:!rounded-[10px] font-['Montserrat'] font-semibold text-[16px leading-[22px] tracking-[0.2px] text-[#000000] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
            >
              <option value=''>
                <p className="font-['Montserrat'] font-semibold text-[16px leading-[22px] tracking-[0.2px] text-[#000000]">
                  Room category
                </p>
              </option>
              {roomData?.categories.map((room, index) => (
                <option key={index} value={room?.roomType}>
                  {room?.roomTypeLabel}
                </option>
              ))}
            </select>
          )}          
        </div>
        {!selectedRoomData?.roomType &&  ((selectedDate?.to && selectedDate?.from) || (selectedDays?.length>0)) && showTab === 'adjustRate' && <p className="self-start text-red-500">Please select room  category first to perform bulk rate update</p> }
        </div>         */}
      </div>

      
      {/* <div className="mt-3">
      {showTab === "adjustRate" && selectedRoomData?.roomType  && <AdjustRate selectedDays={selectedDays} setRoomData={setRoomData} getRoomInRange={getRoomInRange} setSelectedRoomData={setSelectedRoomData}  rooms={selectedRoomData} loading={roomLoading} selectedDate={selectedDate} />}
      {showTab === "adjustRooms" && <AdjustRoom getRoomInRange={getRoomInRange} selectedDate={selectedDate} loading={roomLoading} rooms={roomData} />}
      </div> */}
    </div>
  );
};

export default BulkUpdate;
