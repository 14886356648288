import moment from "moment";

const BookingDetail = ({ bookingDetail }: any) => {
  return (
    <div className="flex flex-col shadow-[0_4px_21px_3px_rgba(0,0,0,0.16)] rounded-[11px] px-2">
      <p className="font-Urbanist font-bold text-[22px] leading-[30px] tracking-[0.2px] text-[#000000]">
        {bookingDetail?.guestDetails?.name}
      </p>
      <div className="flex flex-row items-center gap-x-3">
        <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] tracking-[0.2px] text-[#787878]">
          {bookingDetail?.guestDetails?.phone}
        </p>

        <div className="h-[29px] w-[110px] bg-[#28B28140] flex items-center justify-center  rounded-[4px]">
          <p className="font-['Montserrat'] font-semibold text-[12px] leading-[14px] text-[#0AA46D]">
            {bookingDetail?.isPaid && "confirm"}
          </p>
        </div>
      </div>

      <div className="h-[1px] bg-[#D9D9D9] my-3"></div>

      <div className="flex gap-x-[70px] flex-row">
        <div className="flex gap-y-2 flex-col">
          <div className="flex flex-col">
            <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              Check-In
            </p>
            <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              {moment(bookingDetail?.checkIn).format("DD MMM YYYY")}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              Net Amount
            </p>
            <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              INR {bookingDetail?.amount}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              Reservation
            </p>
            {bookingDetail?.reservation?.map((room) => {
              return (
                <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                  {`${room?.type} - ${room?.count}`}
                </p>
              );
            })}
          </div>

          {bookingDetail?.meals?.length > 0 && (
            <div className="flex flex-col">
              <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                Meals
              </p>
              {bookingDetail?.meals?.map((meal) => {
                return (
                  <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
                    {meal}
                  </p>
                );
              })}
            </div>
          )}
        </div>

        <div className="flex gap-y-2 flex-col">
          <div className="flex flex-col">
            <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              Check-Out
            </p>
            <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              {moment(bookingDetail?.checkOut).format("DD MMM YYYY")}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              Booking Id
            </p>
            <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              {bookingDetail?.bookingId}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-[#787878] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              No. of Adults
            </p>
            <p className="text-[#000000] font-['Montserrat'] font-medium text-[12px] leading-[16px]">
              {bookingDetail?.guestDetails?.adults}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
