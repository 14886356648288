import moment from "moment";
import useApi from "../../../hooks/useApi";
import { useState, useEffect } from "react";
import { API_ROUTES } from "../../../api";
import Card from "../Card";
import { SnakeLoader } from "../../Loader";
import useCommonStore from "../../../store/store";
import { getUTCDate } from "../../../utils/dateFormatter";

const UpcomingBookings = ({ selectedDate,handleBookingDetail }: any) => {
  const [bookingData, setBookingData] = useState([]);
  const {homestayData} = useCommonStore();

  const {
    data: upcomingBookingData,
    error,
    apiClient: upcomingBookingClient,
    loading
  } = useApi();

  const getUpcommingBoking = async () => {
    const url = API_ROUTES.BOOKINGS;
    const options = {
      params: {
        type: "upcoming",
        date: getUTCDate(selectedDate),
        homeStayId: homestayData?._id
      },
    };
    await upcomingBookingClient(url, options);
  };

  useEffect(() => {
    getUpcommingBoking();
  }, [selectedDate,homestayData]);

  useEffect(() => {
    if (upcomingBookingData?.data) {
      setBookingData(upcomingBookingData?.data);
    }
  }, [upcomingBookingData]);


  return (
    <div className="flex flex-col gap-y-5">
      {loading && <SnakeLoader />}
      {!loading &&
        bookingData?.length > 0 &&
        bookingData?.map((booking: any) => {
          return (
            <>
              <Card
                handleBookingDetail={handleBookingDetail}
                bookingData={booking}
              />
            </>
          );
        })}

      {!loading && bookingData?.length === 0 && (
        <div className="flex self-center justify-between items-center shadow-xl px-6 py-4">
          <p className="font-inter font-bold text-[16px] leading-[15px] tracking-[0.5px] text-[#F75D37]">
            No Booking found
          </p>
        </div>
      )}
    </div>
  );
};

export default UpcomingBookings;
