const BulkIcon = ({width="24px",height="24px", color="#787878",isActive}:any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L25 8.03448L13 15.069L1 8.03448L13 1Z"
        stroke={isActive ? '#F75D37' : '#787878'}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 15.069L3.65394 9.59033L1 11.3448L13 18.3793L25 11.3448L22.3461 9.59033L13 15.069Z"
        stroke={isActive ? '#F75D37' : '#787878'}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 18.3793L3.65161 12.8994L1 14.6552L13 21.6897L25 14.6552L22.3484 12.8994L13 18.3793Z"
        stroke={isActive ? '#F75D37' : '#787878'}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 21.6897L3.66555 16.2177L1 17.9655L13 25L25 17.9655L22.3728 16.1953L13 21.6897Z"
        stroke={isActive ? '#F75D37' : '#787878'}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};


export default BulkIcon;