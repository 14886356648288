import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import clsx from "clsx";

type buttonSize = "regular" | "lg" | "md" | "md2" | "sm" | "xs" | "xl";
type buttonVariants = "regular" | "link" | "pill" | "ghost";
type buttonColor = "regular" | "green" | "dark_green" | "black_outline" | "green_gradient" | "orange_gradient" | "orange_link" | "ghost" | "white" | "blue_link";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: buttonSize;
    variant?: buttonVariants;
    color?: buttonColor;
    fullWidth?: boolean;
    children: ReactNode;
    smHidden?: boolean;
    lgHidden?: boolean;
    disabled?:boolean;
}

const sizeClass = {
    regular : "px-4 py-2 text-base",
    sm      : "px-3 py-2",
    md      : "px-[30px] py-3",
    md2     : "px-[20px] py-[4px] lg:px-[40px] lg:py-[6px] text-[8px] lg:text-[20px]",
    lg      : "px-16 py-3 text-base",
    xs      : "p-0 text-[12px] lg:text-base font-semibold",
    xl      :  "text-[16px]"
};

const variantClass = {
    regular : "rounded-lg",
    link    : "border-none bg-transparent underline",
    pill    : "rounded-[30px]",
    ghost   : "border-none bg-transparent"
};

const colorClass = {
    regular         : "text-white bg-[#F75D37] font-['Montserrat']",
    black_outline   : "bg-transparent border-[1px] border-[#1E1E1E] text-[#1E1E1E] font-['Montserrat']",
    green           : "bg-[#28B281] text-white",
    dark_green      : "bg-[#008080] text-white",
    green_gradient  : "bg-gradient-to-r from-[#28B281] to-[#47D8A3] text-white",
    orange_gradient : "bg-gradient-to-r from-[#F75D37] to-[#F794A4] text-white font-['Roboto']",
    orange_link     : "text-[#F75D37]",
    ghost           : "bg-transparent",
    white           : "text-[#FFFFFF]",
    blue_link       : "text-[#3362AB] font-medium font-['Montserrat'] leading-[15px]"
};

const Button = ({
    size = "regular",
    variant = "regular",
    color = "regular",
    fullWidth = false,
    smHidden = false,
    lgHidden = false,
    children,
    disabled=false,
    ...props
}: Props) => (

    <button
        className={clsx(
            "disabled:cursor-not-allowed disabled:opacity-50 font-medium transition-all h-fit ",
            {
                "hidden lg:inline-block" : smHidden,
                "inline-block lg:hidden" : lgHidden,
                "w-full"                 : fullWidth,
                "w-fit"                  : !fullWidth
            },
           

            sizeClass[size],
            colorClass[color],
            variantClass[variant]
        )}
        disabled={disabled}
        {...props}
    >{children}</button>
);

export default Button;