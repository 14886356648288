import React, { memo, useEffect, useRef } from "react";

interface IModal {
  show: boolean;
  onClose?: () => void;
  children: React.ReactElement;
  title?: string;
  className?: string;
  bg?: string;
  preventClose?: boolean;
  noShadow?:string;
  containerClass?:string;
  titleClass?:string;
  titleComponent?:React.ReactElement;
}

const Modal = (props: IModal) => {
  const {
    show,
    onClose,
    children,
    title,
    className,
    bg,
    noShadow,
    containerClass,
    titleClass,
    preventClose = false,
    titleComponent
  } = props;

  const ref = useRef<any>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      if (document.body.style.overflowY === "hidden") {
        document.body.style.overflowY = "auto";
      }
    };
  }, [show]);
  if (!show) {
    return null;
  }
  return (

      <div
        className={`z-[1000000000] fixed inset-0 bg-grey-300/80 w-screen h-screen ${containerClass}`}
      >
        <div className="w-screen h-screen relative flex md:items-center justify-center">
          <div
            ref={ref}
            className={`fixed max-h-screen  md:bottom-auto rounded-lg w-full md:w-fit ${
              bg ? bg : "bg-white"
            } md:min-w-[500px] md:max-w-full min-h-[300px] ${
              noShadow ? "" : "shadow-[0_0px_8px_rgba(194,198,204,0.6)]"
            } flex flex-col`}
          >
            <div
              className={`rounded-t-lg flex justify-between w-full items-center px-8 min-h-[30px] py-4 border-b-[1px] border-grey-300 sticky z-50 ${
                bg ? bg : "bg-white"
              }`}
            >
              {title && (
                <p className={`text-lg text-grey-500 font-bold ${titleClass}`}>
                  {title}
                </p>
              )}
              {titleComponent && titleComponent}
              {!preventClose && (
                <div
                  className="w-[15px] h-[15px] cursor-pointer"
                  onClick={() => onClose()}
                >
                   <svg
                  width="29"
                  height="29"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0625 34.9375C11.8125 34.9375 5.125 28.25 5.125 20C5.125 11.75 11.8125 5.0625 20.0625 5.0625C28.3125 5.0625 35 11.75 35 20C35 28.1875 28.3125 34.9375 20.0625 34.9375ZM20.0625 6.625C12.75 6.625 6.74998 12.625 6.74998 19.9375C6.74998 27.25 12.75 33.25 20.0625 33.25C27.375 33.25 33.375 27.25 33.375 19.9375C33.375 12.625 27.375 6.625 20.0625 6.625Z"
                    fill="#787878"
                  />
                  <path
                    d="M13.5625 27.625L12.375 26.4375L26.5 12.3125L27.6875 13.5L13.5625 27.625Z"
                    fill="#787878"
                  />
                  <path
                    d="M26.5 27.625L12.375 13.5L13.5625 12.3125L27.6875 26.4375L26.5 27.625Z"
                    fill="#787878"
                  />
                </svg>
                </div>
              )}
            </div>
            <div className={`overflow-auto z-[100000] rounded-lg ${className}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
  );
};

export default memo(Modal);
