
export const  isValidMobileNumber=(phoneNumber:string) =>{
    const allowedCharacters = ['+', '-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    for (const char of phoneNumber) {
      if (!allowedCharacters.includes(char)) {
        return false;
      }
    }
    return true;
  }


  export const isPositiveNumber = (value) => {
    if (!isNaN(value) && value >= 0) {
      return true;
    } else {
      return false;
    }
  };


 