import { useEffect, useState } from 'react';
import axios from 'axios';

const useApi = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const apiClient = async (url: string, options = {}) => {
    setLoading(true);

    try {
      const response = await axios(url, {
        ...options,
        withCredentials: true
      });
      setData(response.data);
      setError('');
    } catch (err) {
      console.log(err, 'Error from Use API');
      (err?.response?.status === 400 && err?.response?.data?.message) ? setError(err?.response?.data?.message) : setError('Something went wrong');
      setData(null);
    }
    setLoading(false);
  };

  return { data, loading, error, apiClient };
};

export default useApi;