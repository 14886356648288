
"use client"
import useCommonStore from "../../store/store";
import { useEffect } from "react";

 const HomestaySelector = ({authData}:any) => {
    const {setHomestay} = useCommonStore();

    useEffect(() => {
      if (authData?.homestays?.length ) {
        setHomestay(authData?.homestays[0]);
      }
    }, [authData]);

  const changeHomestay = (e:any)=>{
    const filterData = authData?.homestays?.find(homestay=> homestay?.homeStayId === e.target.value) 
    setHomestay(filterData);
  }

  return (
    <div className="my-3 md:mt-[100px]  lg:pl-[32px] lg:pr-[14px]  2xl:px-[71px] py-[12px] lg:py-2 max-w-[300px]  md:max-w-full">
      {/* <div className="flex items-center py-2 justify-center">
          <p className="text-[16px] text-[#222B45] font-bold font-['Montserrat'] leading-[16px] ">Select Homestay</p>
      </div> */}
      {authData?.homestays?.length > 0 && (
        <select
          defaultValue={authData?.homestays[0]?.homeStayId}
          onChange={changeHomestay}
          id="selectedHomestay"
          name="selectedHomestay"
          className="h-[53px]  px-3 w-full  border-t-[1px] border-b-[1px] border-[#D9D9D9]  font-['Montserrat'] font-semibold text-[16px leading-[22px] tracking-[0.2px] text-[#000000] placeholder:font-['Montserrat'] placeholder:font-normal placeholder:text-[16px] placeholder:leading-[22px] placeholder:tracking-[0.2px] placeholder:text-[#000000]"
        >
          {/* <option value=""> */}
            {/* <p className="font-['Montserrat'] font-semibold text-[16px leading-[22px] tracking-[0.2px] text-[#000000]">
              {authData?.homestays?.length
                ? authData?.homestays[0]?.nameToBeDisplayed
                : "select homestay"}{" "}
            </p> */}
          {/* </option> */}
          {authData?.homestays?.map((homestay, index) => (
            <option key={index} value={homestay?.homeStayId}>
              {homestay?.name || homestay?.nameToBeDisplayed}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default HomestaySelector;
