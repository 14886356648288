import { DayPickerSingleDateController } from "react-dates";
import { useState } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import './style.css'

const SingleDayPicker = ({ value, handleDateChange,enableOutsideRange=false,setCurrentMonth,soldOutDate}) => {

  const dateValue = value ? moment(value) : null;
  const isOutsideRange = (day) => {
    if(enableOutsideRange)
    return day.isBefore(moment().startOf('day'));
    return false
  };

  const renderDayContents = (day) => {
    const isFullyBooked = soldOutDate?.map(date =>
      moment(date)
    ).some(d => d.isSame(day, "day"));

    return (
      <div className={isFullyBooked ? "fully-booked-day" : ""}>

        {day.date()}
      </div>
    );
  };

  return (
    <div>
      <DayPickerSingleDateController
        date={dateValue}
        onDateChange={handleDateChange}
        focused={true}
        numberOfMonths={1}
        isOutsideRange={isOutsideRange}    
        onPrevMonthClick={(newMonth) => setCurrentMonth(moment(newMonth))}
        onNextMonthClick={(newMonth) => setCurrentMonth(moment(newMonth))}      
        renderDayContents={renderDayContents} 
        />
    </div>
  );
};

export default SingleDayPicker;
