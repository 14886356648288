import { useEffect, useState } from "react";
import Input from "../../Input";
import { INPUT_TYPES } from "../../Input";
import Button from "../../Button";
import { EVARIANTS, ECOLOR } from "../../Button/interface";
import OtpInput from "../../OtpInput";
import { isValidMobileNumber } from "../../../utils";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from '../../../api';
import { ELOGIN_TYPE, OTP_LENGTH } from "../interface";
// import './style.css';
import useCommonStore from "../../../store/store";

const Login = () => {

  const [activeStep, setActiveStep] = useState(ELOGIN_TYPE.NUMBER);
  const [showOtpMessage,toggleOtpMessage] = useState(false);
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState("");
  const [error, setError] = useState({
    phone: "",
    otp: "",
  });


  const {setAuthData} = useCommonStore();

  const {
    data: sendOtpData,
    error: sendOtpError,
    loading: sendOtpLoading,
    apiClient: sendOtpApiClient,
  } = useApi();

  const {
    data: verifyOtpData,
    error: verifyOtpError,
    loading: verifyOtpLoading,
    apiClient: verifyOtpApiClient,
  } = useApi();

  const {
    data: resendOtpData,
    error: resendOtpError,
    loading: resendOtpLoading,
    apiClient: resendOtpApiClient,
  } = useApi();


  const onChangeNumber = (e: React.FormEvent<HTMLInputElement>) => {
    const val = (e.target as HTMLInputElement).value;
    if (isValidMobileNumber(val)) {
        setPhone(val);
    }
  };

  const onChangeOtp = (value: string) => {
    setOtp(value);
  };

  const updateActiveStep = (activeStep: ELOGIN_TYPE) => {
    setActiveStep(activeStep);
  };

  const resetError = () => {
    setError({
      phone: "",
      otp: "",
    });
  };

  const onClick = async (e: any) => {
      e?.preventDefault?.();
      if (!isValidMobileNumber(phone)) {
          setError({ ...error, phone: 'Enter valid mobile number' });
          return;
      }
      if (activeStep === ELOGIN_TYPE.NUMBER) {
          const url = API_ROUTES.SEND_OTP;
          const config = {
              method : 'POST',
              data   : { phone, referrer: '', url: window.location.href },
          };
          await sendOtpApiClient(url, config);          
          return;
      }
      if (activeStep === ELOGIN_TYPE.OTP) {
          if (otp.length !== OTP_LENGTH) {
              setError({ ...error, otp: 'Enter valid otp' });
              return;
          } else {
              const url = API_ROUTES.VERIFY_OTP;
              const config = {
                  method : 'POST',
                  data   : { phone, otp },
              };
              await verifyOtpApiClient(url, config);             
              return;
          }
      }
  };


  useEffect(() => {
      if (sendOtpData) {
          updateActiveStep(ELOGIN_TYPE.OTP);
          resetError();
      }
  }, [sendOtpData]);


  useEffect(() => {
      if (verifyOtpData) {
          resetError();
          setAuthData(verifyOtpData?.data)
          window.location.reload();
        
      }
  }, [verifyOtpData]);

  const handleBackButton = () => {
      updateActiveStep(ELOGIN_TYPE.NUMBER);
      setOtp('');
     
  };


  const handleResend = async (e: any) => {
    e?.preventDefault?.();
    const url = API_ROUTES.SEND_OTP;
          const config = {
              method : 'POST',
              data   : { phone, referrer: '', url: window.location.href },
          };
          await resendOtpApiClient(url, config);          
          return;
  };

  useEffect(()=>{
    if(resendOtpData)
    {
      toggleOtpMessage(true);
    }

  },[resendOtpData])

  const otpLabel = `An 4 digit code has been sent to +91 ${phone}`

  return (
    <form onSubmit={onClick}>
      <div className=" md:flex hidden w-[745px] mt-[100px] rounded-[18px] bg-[#FFFFFF] shadow-[0px_0px_30px_5px_rgba(0,0,0,0.14)]">
        <div className="flex  gap-y-[60px] items-center justify-center   rounded-[18px] flex-col flex-1">
          <img
            src="/images/loginImage.jpg"
            className="h-full w-full rounded-[18px]"
          ></img>
        </div>

        <div className="flex flex-col gap-y-8 items-center justify-center flex-1">
          {activeStep === ELOGIN_TYPE.NUMBER && (
            <>
              <div className="flex flex-col gap-y-[36px]">
                <div className="relative">
                  <label
                    htmlFor="mobile"
                    className="absolute top-0 left-3 -mt-2 text-gray-900 text-xs bg-[#FFFFFF] font-semibold  px-1"
                  >
                    Mobile <span className="text-red-500">*</span>
                  </label>
                  <Input
                    autoFocus
                    type={INPUT_TYPES.TEXT}
                    placeholder="Enter Your Number"
                    name="number"
                    value={phone}
                    onChange={onChangeNumber}
                    error={error.phone || sendOtpError}
                    className="w-[286px] h-[40px] p-3 bg-[#FFFFFF] rounded-[8px]  border-[1px] border-[#D2D5DA]"
                  />
                </div>
              </div>
              <Button
                disabled={sendOtpLoading}
                type="submit"
                //  onClick={onClick}
                className="bg-gradient-to-r h-[42px] w-[125px] from-[#F75D37] to-[#F5886E] !rounded-[100px] "
              >
                <p className="text-[16px] leading-[19px] font-semibold font-['Montserrat] text-[#FFFFFF]">
                  Login
                </p>
              </Button>
            </>
          )}

          {activeStep === ELOGIN_TYPE.OTP && (
            <>
              <div className="flex gap-y-3 flex-col">
                <p className="font-['Montserrat'] font-semibold text-[12px] leading-[14px] text-[#484848]">
                  OTP
                </p>
                <OtpInput
                  value={otp}
                  onChange={onChangeOtp}
                  otpLength={OTP_LENGTH}
                  error={error.otp || verifyOtpError}
                />
              </div>
              <div className="flex flex-col gap-y-5 items-center gap-x-2">
                <Button
                  disabled={verifyOtpLoading}
                  onClick={onClick}
                  className="bg-gradient-to-r h-[42px] w-[125px] from-[#F75D37] to-[#F5886E] !rounded-[100px]"
                >
                  <p className="text-[16px] leading-[19px] font-semibold font-['Montserrat'] text-[#FFFFFF]">
                    Verify
                  </p>
                </Button>

                <Button
                  disabled={verifyOtpLoading}
                  onClick={handleResend}
                  variant={EVARIANTS.LINK}
                >
                  <p className="text-[15px] leading-[18px] font-medium font-inter text-[#000000]">
                    Resend OTP
                  </p>
                </Button>
                {showOtpMessage && (
                  <p className="text-green-500 text-center text-xs font-bold mt-1">
                    OTP Resent Successsfully
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="md:hidden flex flex-col h-full w-full rounded-[18px] bg-[#FFFFF]">
        {activeStep === ELOGIN_TYPE.NUMBER && (
          <div className="flex flex-col">
            <div className="h-full min-h-screen">
              <img src="/images/login.jpg"></img>
            </div>
            {/* <div className="h-[200px]">
              <img src="/images/homepage.jpg"></img>
            </div> */}
            <div className="absolute top-[130px]">
              <div className="relative">
                <div className="h-[239px] w-full px-4">
                  <img src="/images/box.png" />
                </div>

                <div className="absolute inset-0 bottom-[80px] flex items-center justify-center">
                  <div className="flex flex-col items-center gap-[-30px]">
                    <img src="/images/logo.png" />
                    <img src="/images/tagline.png" className="mt-[-40px]" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col absolute bottom-0 w-full justify-center h-[267px] bg-gradient-to-r from-[#F75D37] to-[#FF621F] gap-y-5 items-center px-[20px]">
              <>
                <div className="flex flex-col gap-y-2 w-full items-start">
                  <Input
                    autoFocus
                    type={INPUT_TYPES.TEXT}
                    placeholder="Enter phone number"
                    name="number"
                    value={phone}
                    onChange={onChangeNumber}
                    error={error.phone || sendOtpError}
                    className="placeholder:text-[#FFFFFF] text-[#FFFFFF] placeholder:ml-2 border-b-1 bg-[#F75D37] w-full placeholder:font-inter placeholder:text-[14px] focus:outline-none placeholder:leading-[16px] placeholder:font-semibold"
                  />
                  <div className="h-[1px] w-full bg-[#FFFFFF]"></div>
                </div>

                <Button
                  disabled={sendOtpLoading}
                  onClick={onClick}
                  color={ECOLOR.ONE}
                  className=" !rounded-[15x] w-full h-[57px]"
                >
                  <p className="font-inter  font-semibold text-[14px] leading-[16px] text-[#000000]">
                    Login
                  </p>
                </Button>
              </>
            </div>
          </div>
        )}


       

        <div className="md:hidden flex justify-center px-3 items-center">
          {activeStep === ELOGIN_TYPE.OTP && (
            <>
              <div className="flex gap-y-5 flex-col">
                <div className="flex gap-x-[50px] mt-[20px] items-center  flex-row">
                  <Button onClick={handleBackButton} className="!px-0">
                    <img
                      className="w-[18px] items-start h-[15px]"
                      src="/images/back.png"
                    ></img>
                  </Button>

                  <p className="font-inter text-[20px] leading-[24px] text-[#000000] font-semibold">
                    OTP Verification
                  </p>
                </div>

                <div className="flex mt-[150px] flex-col">
                  <p className="font-inter text-[25px] leading-[30px] text-[#000000] font-bold">
                    Enter OTP
                  </p>
                  <p className="font-inter text-[15px] leading-[18px] text-[#595959] font-medium">
                    {otpLabel}
                  </p>
                </div>

                <OtpInput
                  value={otp}
                  onChange={onChangeOtp}
                  otpLength={OTP_LENGTH}
                  error={error.otp || verifyOtpError}
                />

                <div className="mt-[30px] flex flex-col gap-y-4">
                  <Button
                    color={ECOLOR.TWO}
                    variant={EVARIANTS.PILL}
                    onClick={onClick}
                    //   disabled={sendOtpLoading || verifyOtpLoading}
                    isFullWidth={true}
                    className=" !rounded-[15px] !h-[57px]"
                  >
                    <p className="font-['Montserrat'] font-semibold text-[14px] leading-[17px]">
                      Verify
                    </p>
                  </Button>

                  <Button onClick={handleResend} variant={EVARIANTS.LINK}>
                    <p className="font-inter font-medium text-[15px] leading-[18px] text-[#000000]">
                      Resend OTP
                    </p>
                  </Button>

                  {showOtpMessage && (
                    <p className="text-green-500 text-center text-xs font-bold mt-1">
                      OTP Resent Successsfully
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default Login;
