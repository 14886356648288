import RoomCard from "./RoomCard";
import ManageDiscount from "./ManageDiscount";
import ManageRate from "./ManageRate";
import { useState, useEffect } from "react";
import Modal from "../Modal/v2";
import useApi from "../../hooks/useApi";
import { API_ROUTES } from "../../api";
import moment from "moment";
import BlockConfirmation from "./BlockConfirmation";
import { SnakeLoader } from "../Loader";
import Button from "../Button";
import { ECOLOR, EVARIANTS } from "../Button/interface";
import useCommonStore from "../../store/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import { getUTCDate } from "../../utils/dateFormatter";
import SingleDayPicker from "./SingleDatePicker";

const Rates = () => {
  const [showManageRate, toggleManageRate] = useState(null);
  const [showManageDiscount, toggleManageDiscount] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [roomList, setRoomList] = useState([]);
  const [fetchRoomList, setgetRoomList] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showBlockConfirmation, toggleBlockConfirmation] = useState(false);
  const { data: roomsData, error, loading, apiClient: getRoomClient } = useApi();
  const [available, setAvailable] = useState([]);
  const {homestayData}  = useCommonStore();
  const [soldOutDate,setSoldOutDate] = useState([]);


  const { data: blockPropertyResponse, error: blockPropertyError, loading: blockPropertyLoading, apiClient: blockPropertyClient } = useApi();
  const { data: blockRoomResponse, error: blockRoomError, loading: blockRoomLoading, apiClient: blockRoomClient } = useApi();
  const [currentMonth, setCurrentMonth] = useState(moment()); 
  const { data: roomResponse, error:roomError, loading:roomLoading, apiClient: roomClient } = useApi();
  
  const blockRoom = async () => {
    const url = API_ROUTES.BLOCK_ROOM;
    const options = {
      method: "POST",
      data: {
        date: getUTCDate(selectedDate),
        bookedRooms: available,
        homeStayId:homestayData?._id
      },
    };
    await blockRoomClient(url, options);
  };

  const blockProperty = async () => {
    const url = API_ROUTES.BLOCK_PROPERTY;
    const options = {
      method: "POST",
      data: {
        type: !isChecked ? "block" : "unblock",
        date: getUTCDate(selectedDate),
        homeStayId:homestayData?._id
      },
    };
    await blockPropertyClient(url, options);
  };


  useEffect(() => {
    if (blockPropertyResponse?.status) {
      setIsChecked(!isChecked);
      getRoomList();
    }
  }, [blockPropertyResponse]);

  useEffect(() => {
    getRoomList();
  }, [selectedDate, fetchRoomList,homestayData]);



  const getRoomList = async () => {
    const url = API_ROUTES.ROOMLIST;
    const options = {
      params: {
        date: getUTCDate(selectedDate),
        homeStayId: homestayData?._id
      },
    };
    await getRoomClient(url, options);
  };

  useEffect(() => {
    if (roomsData?.data) {
      setRoomList(roomsData?.data?.categories);
      setIsChecked(roomsData?.data?.isEntirePropertySoldOut);
      setgetRoomList(false);
    }
  }, [roomsData]);

  const handleManageRate = (room: any) => {
    toggleManageRate(room);
  };

  const handleDiscount = (room: any) => {
    toggleManageDiscount(room);
  };


  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleCheckbox = () => {
    toggleBlockConfirmation(true);
  }

  const handleSubmit = () => {
    blockProperty();
    toggleBlockConfirmation(false);
  }

  const handleCancel = () => {
    setAvailable([]);
    getRoomList();
  }

  const handleSave = () => {
    blockRoom();
  };

  useEffect(() => {
    if (blockRoomResponse?.status) {
      getRoomList();
      setAvailable([]);
    }
  }, [blockRoomResponse]);



  const getRoomInRange = async ({startDate,endDate}) => {
    const url = API_ROUTES.PROPERY_SOLD_OUT_STATUS;
    const options = {
      params: {
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.format("YYYY-MM-DD"),
        homeStayId : homestayData?._id
      },
    };
    await roomClient(url, options);
  };


  useEffect(()=>{
    if(currentMonth && homestayData){
      const today = moment();
      const firstDateOfMonth = currentMonth.clone().startOf('month');
      const lastDateOfMonth = currentMonth.clone().endOf('month');
      const adjustedFirstDate = today.isAfter(firstDateOfMonth) ? today : firstDateOfMonth;  
      getRoomInRange({startDate:adjustedFirstDate,endDate:lastDateOfMonth});
    } 
  },[currentMonth,homestayData,isChecked])

  useEffect(() => {
    if (roomResponse?.status) {  
    setSoldOutDate(roomResponse?.data);
    }
  }, [roomResponse]);

  return (
    <>
      <div className="md:mt-[100px] mb-[100px]  w-11/12 md:max-w-[1440px] px-0 lg:px-24 mx-auto sm:my-24">
        <div className="w-full my-3 flex items-center justify-center">
          <SingleDayPicker
            value={selectedDate}
            handleDateChange={handleDateChange}
            enableOutsideRange={true}
            setCurrentMonth={setCurrentMonth}
            soldOutDate={soldOutDate}
          />
        </div>

        <div className="h-[59px] bg-[#ECEAEA] md:bg-[#FFFFFF] flex items-center md:gap-x-6 justify-around md:justify-end mb-2 px-3 ">
          <p className="font-['Montserrat'] font-medium text-[14px] leading-[16px] text-[#222B45]">
            Property Sold Out
          </p>
          <label className="switch">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckbox}
            />
            <span className="slider round"></span>
          </label>
        </div>

        {available?.length > 0 && (
          <div className="sticky md:top-[80px] top-3  gap-x-3   flex flex-row justify-center items-center  px-6 p-4 rounded-lg">
            <Button
              variant={EVARIANTS.PILL}
              color={ECOLOR.FIVE}
              onClick={handleCancel}
              className="!md:px-[40px] md:w-[150px]"
            >
              <p className="md:text-[16px] text-[14px] font-['Montserrat'] font-medium leading-[16px]">
                Cancel
              </p>
            </Button>
            <Button
              variant={EVARIANTS.PILL}
              color={ECOLOR.TWO}
              onClick={handleSave}
              className="!md:px-[40px] md:w-[150px]"
            >
              <p className="md:text-[16px] text-[14px] font-['Montserrat'] font-medium leading-[16px]">
                Save
              </p>
            </Button>
          </div>
        )}

        <div className="px-3 md:mt-[30px] mt-[20px] flex flex-col gap-y-4">
          {loading ? (
            <SnakeLoader />
          ) : (
            <>
              {roomList.length > 0 ?
                roomList.map((room) => {
                  return (
                    <RoomCard
                      room={room}
                      handleManageRate={() => handleManageRate(room)}
                      handleDiscount={() => handleDiscount(room)}
                      setAvailable={setAvailable}
                      available={available}
                    />
                  );
                }) : <div className="flex self-center justify-between items-center shadow-xl px-6 py-4"><p className="font-inter font-bold text-[16px] leading-[15px] tracking-[0.5px] text-[#F75D37]">No Rooms found</p></div>}
            </>
          )}
        </div>
      </div>

      {showManageDiscount && (
        <Modal
          show={showManageDiscount}
          preventClose={true}
          onClose={() => toggleManageRate(false)}
          title={showManageDiscount?.roomTypeLabel}
        >
          <ManageDiscount
            setgetRoomList={setgetRoomList}
            roomData={showManageDiscount}
            toggleManageDiscount={toggleManageDiscount}
          />
        </Modal>
      )}

      {showManageRate && (
        <Modal
          show={showManageRate}
          preventClose={true}
          onClose={() => toggleManageRate(false)}
          title={showManageRate?.roomTypeLabel}
        >
          <ManageRate
          selectedDate={selectedDate}
            setgetRoomList={setgetRoomList}
            roomData={showManageRate}
            toggleManageRate={toggleManageRate}
          />
        </Modal>
      )}

      {showBlockConfirmation && (
        <Modal
          show={showBlockConfirmation}
          preventClose={true}
          onClose={() => toggleBlockConfirmation(false)}
          className="flex flex item-center justify-center px-3 py-4"
        >
          <BlockConfirmation
            toggleBlockConfirmation={toggleBlockConfirmation}
            isChecked={isChecked}
            handleSubmit={handleSubmit}
          />
        </Modal>
      )}
    </>
  );
};

export default Rates;
