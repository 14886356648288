import { Link } from "react-router-dom";
import Logo from "../Logo";
import NavItems from "./NavItem";
import { useScrolled } from "../../hooks/useScrolled";

const Navbar = ({ isAuthenticated }: any) => {
  const scrolled = useScrolled();

  return (
    <>
      <nav
        className={`w-full h-[52px] lg:h-[80px] md:max-w-[1920px] flex justify-between items-center px-[20px] lg:pl-[32px] lg:pr-[14px]  2xl:px-[71px] py-[12px] lg:py-2 md:fixed md:top-0 !z-50
                      ${scrolled
            ? "bg-[#FFF] shadow-[0px_0px_8px_0px_rgba(194,198,204,0.50)]"
            : "bg-[#E6ECEEE0] backdrop-blur-[2px]"
          }`}
      >
        <Link to="/">
          <div className="w-20 md:w-32 h-6 md:h-11 relative flex">
            <Logo className="self-center" />
          </div>
        </Link>
        <div className="flex justify-end items-center gap-x-[48px] 2xl:gap-x-[64px]">
          <div className="hidden md:inline-block md:flex-1">
            <NavItems isAuthenticated={isAuthenticated} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
