import { useEffect, useState } from "react";

import { API_ROUTES } from "../api";

import useCommonStore from "../store/store";
import useApi from "./useApi";

const useEnquiryStatus = ({ confirmationRequired, homestayObjectId, enquiryId }: { confirmationRequired?: boolean, homestayObjectId?: string, enquiryId?: string }) => {
    const [enquiryStatus, setEnquiryStatus] = useState(null);
    const { authData } = useCommonStore() || {};
    const userObjectId = authData?.data?._id;

    const {
        data: enquiryData,
        apiClient: homestayEnquiryApi
    } = useApi();

    const getHomestayEnquiry = async () => {
        const url = API_ROUTES.HOMESTAY_ENQUIRY;
        const config = {
            method : "GET",
            params : {
                ...(userObjectId ? {userId: userObjectId} : {}),
                ...(homestayObjectId ? {homestayId: homestayObjectId} : {}),
                ...(enquiryId ? {enquiryId: enquiryId} : {}),
            },
        };
        await homestayEnquiryApi(url, config);
    };

    useEffect(() => {
        if ((confirmationRequired && userObjectId) || enquiryId) {
            getHomestayEnquiry();
        }
    }, [homestayObjectId, userObjectId, enquiryId]);

    useEffect(() => {
        if (enquiryData && enquiryData.data) setEnquiryStatus(enquiryData.data);
    }, [enquiryData]);
    return {enquiryData: enquiryStatus, getHomestayEnquiry};
};

export default useEnquiryStatus;