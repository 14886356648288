import React, { useState,useEffect } from "react";
import Button from "../Button";

const Counter = ({ room,setAvailable,available }) => {
  const [count, setCount] = useState(null);

  useEffect(() => {
    if (room) {
      setCount(room?.available);
    }
  }, [room]);

  const updateAvailable = (newCount) => {
    const existingIndex = available.findIndex(item => item.type === room?.roomType);

    if (existingIndex !== -1) {
      setAvailable(prev => {
        const updatedAvailable = [...prev];
        updatedAvailable[existingIndex] = {
          ...updatedAvailable[existingIndex],
          available: newCount
        };
        return updatedAvailable;
      });
    } else {
      setAvailable(prev => ([
        ...prev,
        {
          type: room?.roomType,
          available: newCount
        }
      ]));
    }
  };

  const increment = () => {
    const newCount = count + 1;
    setCount(newCount);
    updateAvailable(newCount);
  };

  const decrement = () => {
    if (count === 0) return; 
    const newCount = count - 1;
    setCount(newCount);
    updateAvailable(newCount);
  };

  return (
    <div className="flex w-[147px] items-center gap-x-4 justify-between">
      <Button
        onClick={decrement}
        disabled={count === 0 ? true : false}
        className="rounded-[12px] !w-[35px] !h-[35px] !bg-[#E8F8EF] !px-[0px]"
      >
        <p className="font-['Urbanist'] font-semibold text-[15px] leading-[21px] tracking-[0.2px] text-[#202020]">
          -
        </p>
      </Button>

      <p className="font-['Montserrat'] fond-semibold leading-[28px] tracking-[0.2px] text-[20px] text-[#202020]">
        {count}
      </p>

      <Button
        onClick={increment}
        disabled={count === room?.rooms ? true : false}
        className="rounded-[12px] !w-[35px] !h-[35px] !bg-[#E8F8EF] !px-[0px]"
      >
        <p className="font-['Urbanist'] font-semibold text-[15px] leading-[21px] tracking-[0.2px] text-[#202020]">
          +
        </p>
      </Button>
    </div>
  );
};

export default Counter;
