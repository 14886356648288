

import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <main className='mt-5 md:mt-24  text-center'>
      <div>
        <div className="relative !w-full h-[370px] md:h-[1024px] aspect-auto">
          <Link to="/" >
            <img src="/images/pageNotFound/404.svg" alt="Page not Found"  className="cursor-pointer"/>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default PageNotFound;
