import Card from "../Card";
import Modal from "../../Modal/v2";
import { useEffect, useState } from "react";
import { SnakeLoader } from "../../Loader";
import Button from "../../Button";
import { EVARIANTS, ECOLOR } from "../../Button/interface";
import useApi from "../../../hooks/useApi";
import { API_ROUTES } from "../../../api";
import EditOccupancy from "../EditOccupancy";
import moment from "moment";
import useCommonStore from "../../../store/store";
import { getUTCDate } from "../../../utils/dateFormatter";

const AdjustRoom = ({ rooms, loading, selectedDate, getRoomInRange }: any) => {
  const [isChecked, setIsChecked] = useState(true);
  const [showOccupancyModal, toggleOccupacyModal] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const [available, setAvailable] = useState([]);
  const {homestayData} = useCommonStore();
  const { data: blockBulkResponse, error: bulkError, loading: bulkLoading, apiClient: blockBulkClient } = useApi();
  const { data: blockResponse, error: blockError, loading: blockLoading, apiClient: blockPropertyClient } = useApi();


  const blockRoom = async () => {
    const url = API_ROUTES.BULK_ROOM_BLOCK;
    const options = {
      method: "POST",
      data: {
        from: getUTCDate(selectedDate?.from),
        to: getUTCDate(selectedDate?.to),
        bookedRooms: available,
        homeStayId: homestayData?._id
      },
    };
    await blockBulkClient(url, options);
  };

  const blockPropertBulk = async () => {
    const url = API_ROUTES.BULK_PROPERTY_BLOCK;
    const options = {
      method: "POST",
      data: {
        type: !isChecked ? "block" : "unblock",
        from: getUTCDate(selectedDate?.from),
        to: getUTCDate(selectedDate?.to),
        homeStayId: homestayData?._id
      },
    };

    await blockPropertyClient(url, options);
  };

  useEffect(() => {
    if (blockResponse?.status) {
      setIsChecked(!isChecked);
      getRoomInRange();
    }
  }, [blockResponse]);



  useEffect(() => {
    if (blockBulkResponse?.status) {
      setAvailable([]);
      getRoomInRange();
    }
  }, [blockBulkResponse]);

  useEffect(() => {
    if (rooms?.categories?.length) {
      setRoomList(rooms?.categories)
      setIsChecked(rooms?.isEntirePropertySoldOut)
    }

  }, [rooms])


  const handleToggle = () => {
    setIsChecked(!isChecked);
    blockPropertBulk();
  };

  const handleCancel = () => {
    setAvailable([]);
    getRoomInRange();

  }

  const handleSave = () => {
    blockRoom();
  }

  return (
    <>
      {roomList?.length > 0 && (
        <div className="h-[59px] flex items-center md:justify-end justify-around gap-x-6 mb-2">
          <p className="font-['Montserrat'] font-medium text-[17px] leading-[23px] text-[#202020]">
            Entire Property
          </p>
          <label className="switch">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleToggle}
            />
            <span className="slider round"></span>
          </label>
        </div>
      )}

      {available?.length > 0 && (
        <div className="sticky md:top-[80px] top-3  gap-x-3   flex flex-row justify-center items-center  px-6 p-4 rounded-lg">
          <Button
            variant={EVARIANTS.PILL}
            color={ECOLOR.FIVE}
            onClick={handleCancel}
            className="!md:px-[40px] md:w-[150px]"
          >
            <p className="md:text-[16px] text-[14px] font-['Montserrat'] font-medium leading-[16px]">
              Cancel
            </p>
          </Button>
          <Button
            variant={EVARIANTS.PILL}
            color={ECOLOR.TWO}
            onClick={handleSave}
            className="!md:px-[40px] md:w-[150px]"
          >
            <p className="md:text-[16px] text-[14px] font-['Montserrat'] font-medium leading-[16px]">
              Save
            </p>
          </Button>
        </div>
      )}
      <div className="flex flex-col gap-y-5">
        {loading && <SnakeLoader />}
        {roomList?.length > 0 &&
          !loading &&
          roomList.map((room) => {
            return (
              <Card
                room={room}
                setAvailable={setAvailable}
                available={available}
                toggleOccupacyModal={toggleOccupacyModal}
              />
            );
          })}
      </div>

      {/* {showManageDiscount && (
        <Modal show={showManageDiscount} preventClose={true}>
          <ManageDiscount
            selectedDate={selectedDate}
            setgetRoomList={setgetRoomList}
            roomData={showManageDiscount}
            toggleManageDiscount={toggleManageDiscount}
          />
        </Modal>
      )}     */}

      {showOccupancyModal && (
        <Modal
          show={showOccupancyModal}
          title="Change Room Occupancy"
          onClose={() => toggleOccupacyModal(false)}
        >
          <EditOccupancy />
        </Modal>
      )}
    </>
  );
};

export default AdjustRoom;
