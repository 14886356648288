import Button from "../../Button";
import { EVARIANTS, ECOLOR } from "../../Button/interface";
import Modal from "../../Modal";
import ManageDiscount from "../ManageDiscount";
import ManageRate from "../ManageRate";
import { useState } from "react";
import Counter from "../../Counter";

const RoomCard = ({ handleManageRate, handleDiscount ,room,setAvailable,available}: any) => {

  return (
    <>
      <div className="h-full flex md:hidden flex-col  rounded-[16px] w-full shadow-[0_4px_21px_3px_rgba(0,0,0,0.16)] px-2 py-3">
        <div className="flex flex-row gap-x-2">
          <div className="h-[120px] w-[88px] rounded-md">
            <img src={room?.image} className="rounded-md w-full h-full"></img>
          </div>
          <div className="flex flex-col gap-y-3">
            <p className="text-[14px] font-['Montserrat'] fond-medium leading-[16px] text-[#222B45]">
              {room?.roomTypeLabel}
            </p>
            <p className="text-[14px] font-['Montserrat'] fond-medium leading-[16px] text-[#9A9A9A]">
            Price: {room?.roomPrice}/night
          </p>
            <p className="text-[12px] font-['Montserrat'] fond-medium leading-[16px] text-[#8F9BB3]">
              Accomodates {room?.maxAdults} People
            </p>
            <div className="flex flex-row gap-x-2">
              <Button
                variant={EVARIANTS.LINK}
                onClick={handleManageRate}
                className="!px-[0px]"
              >
                <p className=" underline text-[14px] font-['Montserrat'] font-semibold leading-[16px] text-[#3362AB] ">
                  Manage Rates
                </p>
              </Button>
              {/* <Button
                variant={EVARIANTS.LINK}
                onClick={handleDiscount}
                className="!px-[0px]"
              >
                <p className="underline text-[14px] font-['Montserrat'] font-semibold leading-[16px] text-[#3362AB] ">
                  Manage Discount
                </p>
              </Button> */}
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center mt-3">
          <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-[#F9B50B]">
            Total Rooms: {room?.rooms}
          </p>
          <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-[#F75D37]">
            Booked: {room?.booked}
          </p>
          <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-[#28B281]">
            Available: {room?.available}
          </p>
        </div>

        <div className="h-[48px] border border-[#EDEDEE] rounded-[12px] mt-3 flex items-center justify-around flex-row">
          <p className="font-['Montserrat'] font-medium text-[14px] leading-[16px] text-[#8F9BB3]">
            Available
          </p>
          <Counter room={room} setAvailable={setAvailable} available={available} />
        </div>
      </div>

      <div className="h-full hidden md:flex flex-row justify-between rounded-[16px] shadow-[0_4px_21px_3px_rgba(0,0,0,0.16)] w-full  px-2 py-3">
        <div className="flex flex-row gap-x-[40px]">
          <div className="h-[186px] w-[223px]">
            <img src={room?.image} className="rounded-lg w-full h-full"></img>
          </div>
          <div className="flex flex-col justify-between">
            <p className="text-[14px] font-['Montserrat'] fond-medium leading-[16px] text-[#222B45]">
            {room?.roomTypeLabel}
            </p>
            <p className="text-[14px] font-['Montserrat'] fond-medium leading-[16px] text-[#9A9A9A]">
            Price: {room?.roomPrice}/night
          </p>
            <p className="text-[12px] font-['Montserrat'] fond-medium leading-[16px] text-[#8F9BB3]">
              Max Capacity : {room?.maxAdults} People
            </p>

            <div className="flex flex-row justify-between items-center mt-3">
              <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-[#F9B50B]">
                Total Rooms: {room?.rooms}
              </p>
              <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-[#F75D37]">
                Booked: {room?.booked}
              </p>
              <p className="font-['Montserrat'] font-medium text-[12px] leading-[16px] text-[#28B281]">
                Available: {room?.available}
              </p>
            </div>

            <div className="h-[48px] border border-[#EDEDEE] rounded-[12px]  flex items-center justify-around px-[30px] flex-row w-full gap-x-[50px]">
              <p className="font-['Montserrat'] font-medium text-[14px] leading-[16px] text-[#8F9BB3]">
                Available
              </p>
              <Counter room={room} setAvailable={setAvailable} available={available} />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-end gap-y-3 pr-[30px]">
          <Button
            variant={EVARIANTS.LINK}
            onClick={handleManageRate}
            className="!px-[0px]"
          >
            <p className=" underline text-[14px] font-['Montserrat'] font-semibold leading-[16px] text-[#3362AB] ">
              Manage Rates
            </p>
          </Button>
          {/* <Button
            variant={EVARIANTS.LINK}
            onClick={handleDiscount}
            className="!px-[0px]"
          >
            <p className="underline text-[14px] font-['Montserrat'] font-semibold leading-[16px] text-[#3362AB] ">
              Manage Discount
            </p>
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default RoomCard;
