export enum ELOGIN_TYPE {
    OTP = 'OTP',
    NUMBER = 'NUMBER',
}
  
export const LOGIN_HEADINGS = {
    [ELOGIN_TYPE.NUMBER] : 'Login or Signup',
    [ELOGIN_TYPE.OTP]    : 'OTP',
};

export const OTP_LENGTH = 4;

export interface ILOGIN_TYPE {
    onLoginSuccess?: () => void;
    toggleModal?: () => void;
    phone?: string;
  }
  